import React from "react";
import useApiServices from "./useApiService";
import {
  BOX_DATA_BY_CONDITION,
  GURANTEE_MULTIPLE_FILTER_API,
  PAYIN_MULTIPLE_FILTER_API,
  PAYOUT_MULTIPLE_FILTER_API,
  RECONCILIATION_MULTIPLE_FILTER_API,
  WITHDRAW_MULTIPLE_FILTER_API,
} from "./Constants";

/**
 * This is custom hook, it has various filter api calling methods.
 *
 * @returns {ReactNode} A method that takes props then prepare payload and calls the filter api.
 */

const useFilterServices = () => {
  const { apiCallFnforPost } = useApiServices();

  const USE_PAYOUT_MULTIPLE_FILTER_API = async (
    Url,
    amount,
    startAmount,
    endAmount,
    createDate,
    startDate,
    endDate,
    email,
    mobile,
    RefID,
    txnId,
    rrn,
    txnStatus,
    transferType,
    upi,
    mid,
    setData,
    setTransactionDetails,
    page,
    size
  ) => {
    try {
      const response = await apiCallFnforPost(
        size === undefined ? Url : `${Url}?size=${size}&page=${page}`,
        {
          amount: amount ? amount.trim() : "",
          startAmount: startAmount ? startAmount.trim() : "",
          endAmount: endAmount ? endAmount.trim() : "",
          createDate: createDate ? createDate.trim() : "",
          startDate: startDate ? startDate.trim() : "",
          endDate: endDate ? endDate.trim() : "",
          email: email ? email.trim() : "",
          mobile: mobile ? mobile.trim() : "",
          referenceId: RefID ? RefID.trim() : "",
          txnId: txnId ? txnId.trim() : "",
          rrn: rrn ? rrn.trim() : "",
          txnStatus: txnStatus ? txnStatus.trim() : "",
          txnType: transferType ? transferType.trim() : "",
          upi: upi ? upi.trim() : "",
          mid: mid,
        }
      );
      if (response.statusCode === 200) {
        setData(
          response?.data?.map((item, i) => {
            return {
              id: i,
              ...item,
              remark:
                item?.remark === "SurCharge"
                  ? `SurCharge of ${item.reference?.split("-")[0]}`
                  : item?.remark,
              callbackJsonExist:
                item?.callbackJson != null ? "Received" : "Not Received",
            };
          })
        );
        setTransactionDetails(response?.data1);
      } else {
        setData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const USE_COMMON_MULTIPLE_FILTER_API = async (
    Url,
    date,
    endDate,
    startDate,
    status,
    mid,
    reportType,
    startAmount,
    endAmount,
    amount,
    setData,
    page,
    size
  ) => {
    try {
      const response = await apiCallFnforPost(
        size === undefined ? Url : `${Url}?size=${size}&page=${page}`,
        {
          date: date ? date.trim() : "",
          amount: amount ? amount : 0,
          startDate: startDate ? startDate.trim() : "",
          endDate: endDate ? endDate.trim() : "",
          status: status ? status.trim() : "",
          mid: mid,
          type: reportType,
          startAmount: startAmount ? startAmount : 0,
          endAmount: endAmount ? endAmount : 0,
        }
      );

      if (response.statusCode === 200) {
        setData(
          response?.data?.map((item, i) => {
            return {
              id: i,
              ...item,
              remark:
                item?.remark === "SurCharge"
                  ? `SurCharge of ${item.reference?.split("-")[0]}`
                  : item?.remark,
              requestedAmount: item?.actualAmount ? item?.actualAmount : "",
              callbackJsonExist:
                item?.callbackJson != null ? "Received" : "Not Received",
            };
          })
        );
      } else {
        setData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const USE_COMMON_ADVANCE_FILTER_API = async (
    Url,
    email,
    mobileNo,
    orderNo,
    txnId,
    utr,
    vpa,
    reportType,
    mid,
    setData,
    page,
    size
  ) => {
    console.log(
      Url,
      email,
      mobileNo,
      orderNo,
      txnId,
      utr,
      vpa,
      reportType,
      mid,
      setData,
      page,
      size
    );
    try {
      const response = await apiCallFnforPost(
        // size === undefined ? Url : `${Url}?size=${size}&page=${page}`,
        Url,
        {
          email: email ? email.trim() : "",
          mobileNo: mobileNo ? mobileNo.trim() : "",
          orderNo: orderNo ? orderNo.trim() : "",
          txnId: txnId ? txnId.trim() : "",
          type: reportType,
          mid,
          utr: utr ? utr.trim() : "",
          vpa: vpa ? vpa.trim() : "",
        }
      );

      if (response.statusCode === 200) {
        setData(
          response?.data?.map((item, i) => {
            return {
              id: i,
              ...item,
              mobile: item?.mobileNo ? item?.mobileNo : "",
            };
          })
        );
      } else {
        setData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const USE_CHARGEBACK_MULTIPLE_FILTER_API = async (
    Url,
    amount,
    startAmount,
    endAmount,
    transactionDate,
    startDate,
    endDate,
    chargeBackUploadDate,
    startDate1,
    endDate1,
    email,
    mobile,
    payerVpa,
    rrn,
    transactionUid,
    UPItransactionID,
    mid,
    setData,
    setTransactionDetails,
    page,
    size
  ) => {
    try {
      const response = await apiCallFnforPost(
        size === undefined ? Url : `${Url}?size=${size}&page=${page}`,
        {
          chargeBackAmount: amount ? amount.trim() : "",
          startAmount: startAmount ? startAmount.trim() : "",
          endAmount: endAmount ? endAmount.trim() : "",
          startDate: startDate ? startDate.trim() : "",
          endDate: endDate ? endDate.trim() : "",
          transactionDate: transactionDate ? transactionDate.trim() : "",
          chargeBackUploadStartDate: startDate1 ? startDate1.trim() : "",
          chargeBackUploadEndDate: endDate1 ? endDate1.trim() : "",
          chargeBackUploadDate: chargeBackUploadDate
            ? chargeBackUploadDate.trim()
            : "",
          mid: mid,
          email: email ? email.trim() : "",
          mobile: mobile ? mobile.trim() : "",
          payerVpa: payerVpa ? payerVpa.trim() : "",
          rrn: rrn ? rrn.trim() : "",
          transactionUid: transactionUid ? transactionUid.trim() : "",
          upiTransactionId: UPItransactionID ? UPItransactionID.trim() : "",
        }
      );
      setData(response.data);
      setTransactionDetails(response?.data1);
    } catch (error) {
      console.log(error);
    }
  };

  const USE_GURANTEE_MULTIPLE_FILTER_API = async (
    startAmount,
    endAmount,
    amount,
    netAmount,
    startDate,
    endDate,
    date,
    utr,
    mid,
    setData
  ) => {
    console.log(startAmount, endAmount);
    try {
      const response = await apiCallFnforPost(GURANTEE_MULTIPLE_FILTER_API, {
        startGuarantyAmount: startAmount ? startAmount.trim() : "",
        endGuarantyAmount: endAmount ? endAmount.trim() : "",
        guarantyAmount: amount ? amount.trim() : "",
        netAmount: netAmount ? netAmount.trim() : "",
        startDate: startDate ? startDate.trim() : "",
        enddate: endDate ? endDate.trim() : "",
        transactionDate: date ? date.trim() : "",
        utrNumber: utr ? utr.trim() : "",
        mid: mid,
      });

      setData(
        response?.data?.map((item, i) => {
          return {
            id: i,
            ...item,
          };
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const USE_SETTLEMENT_MULTIPLE_FILTER_API = async (
    Url,
    startAmount,
    endAmount,
    payinAmount,
    startPayinAmount,
    endPayinAmount,
    startDate,
    endDate,
    txnId,
    mid,
    orderNo,
    vpa,
    setData,
    setTransactionDetails,
    page,
    size
  ) => {
    try {
      const response = await apiCallFnforPost(
        size === undefined ? Url : `${Url}?size=${size}&page=${page}`,
        {
          endAmount: endAmount ? endAmount.trim() : 0,
          endDate: endDate,
          endPayinAmount: endPayinAmount ? endPayinAmount : 0,
          mid: mid,
          orderNo: orderNo,
          payinAmount: payinAmount ? payinAmount : 0,
          startAmount: startAmount ? startAmount : 0,
          startDate: startDate,
          startPayinAmount: startPayinAmount ? startPayinAmount : 0,
          txnId: txnId ? txnId.trim() : "",
          vpa: vpa ? vpa : "",
        }
      );

      if (response.statusCode === 200) {
        setData(
          response?.data?.map((item, i) => {
            return {
              id: i,
              ...item,
              SettledAmountMod:
                item?.netAmount - (item?.deposoitAmount + item?.holdAmount),
            };
          })
        );
        setTransactionDetails({
          successCount: null,
          failureCount: null,
          sumOfSuccessAmount: response?.totalAmount,
          settlementAmount: null,
          withdrawlAmount: null,
          txnCount: response?.numberOfTransactions,
          pendingCount: null,
          totalAmount: null,
          txnCountt: response?.numberOfTransactions,
        });
      } else {
        setData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const USE_RECONCILIATION_MULTIPLE_FILTER_API = async (
    Url,
    amount,
    endAmount,
    endDate,
    mid,
    payerVpa,
    rrn,
    startAmount,
    startDate,
    txnId,
    setData,
    setTransactionDetails,
    page,
    size
  ) => {
    try {
      const response = await apiCallFnforPost(
        size === undefined ? Url : `${Url}?size=${size}&page=${page}`,
        {
          amount: amount ? amount.trim() : 0,
          endAmount: endAmount ? endAmount.trim() : 0,
          endDate: endDate ? endDate.trim() : "",
          mid: mid,
          payerVpa: payerVpa ? payerVpa.trim() : "",
          rrn: rrn ? rrn.trim() : "",
          startAmount: startAmount ? startAmount.trim() : 0,
          startDate: startDate ? startDate.trim() : "",
          txnId: txnId ? txnId : "",
        }
      );

      if (response.statusCode === 200) {
        setData(
          response.data.map((item, i) => {
            return {
              id: i,
              ...item,
            };
          })
        );
        setTransactionDetails(response?.data1);
      } else {
        setData([]);
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const USE_WITHDRAW_MULTIPLE_FILTER_API = async (
    Url,
    amount,
    startAmount,
    endAmount,
    startDate,
    endDate,
    startDate1,
    endDate1,
    txnDate,
    Date,
    utr,
    status,
    mid,
    setData,
    setTransactionDetails,
    page,
    size
  ) => {
    try {
      const response = await apiCallFnforPost(
        size === undefined ? Url : `${Url}?size=${size}&page=${page}`,
        {
          amount: amount ? amount.trim() : 0,
          startAmount: startAmount ? startAmount.trim() : 0,
          endAmount: endAmount ? endAmount.trim() : 0,
          startDate: startDate ? startDate.trim() : "",
          endDate: endDate ? endDate.trim() : "",
          createStartDate: startDate1 ? startDate1.trim() : "",
          createEndDate: endDate1 ? endDate1.trim() : "",

          txnDate: txnDate ? txnDate.trim() : "",
          createDate: Date ? Date.trim() : "",
          utr: utr ? utr.trim() : "",
          status: status ? status.trim() : "",
          mid: mid,
        }
      );
      if (response.statusCode === 200) {
        setData(
          response?.data?.map((item, i) => {
            return {
              id: i,
              requestedAmount: item?.actualAmount || "NA",
              ...item,
            };
          })
        );
        setTransactionDetails(response?.data1);
      } else {
        setData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const USE_PAYIN_MULTIPLE_FILTER_API = async (
    Url,
    amountSearchValue,
    bankLocationSearchValue,
    custLocationSearchValue,
    dateSearchValue,
    endAmountSearchValue,
    endDateSearchValue,
    greaterAmountSearchValue,
    ipAddress,
    lessAmountSearchValue,
    mid,
    payerVPASearchValue,
    paymentTypeSearchvalue,
    startAmountSearchValue,
    startDateSearchValue,
    transectionStatusSearchValue,
    txnresponseCodeSearchValue,
    setData,
    setTransactionDetails,
    page,
    size
  ) => {
    try {
      const response = await apiCallFnforPost(
        `${Url}?size=${size}&page=${page}`,
        {
          amount: amountSearchValue ? amountSearchValue?.trim() : 0,
          bankLocation: bankLocationSearchValue
            ? bankLocationSearchValue?.trim()
            : "",
          customerLocation: custLocationSearchValue
            ? custLocationSearchValue?.trim()
            : "",

          date: dateSearchValue ? dateSearchValue?.trim() : "",
          endAmount: endAmountSearchValue ? endAmountSearchValue?.trim() : 0,
          endDate: endDateSearchValue ? endDateSearchValue?.trim() : "",
          greterAmount: greaterAmountSearchValue
            ? greaterAmountSearchValue?.trim()
            : 0,
          ipAddress: ipAddress.trim(),
          lessAmount: lessAmountSearchValue ? lessAmountSearchValue?.trim() : 0, // Include mobile value
          mid: mid,
          payerVpa: payerVPASearchValue ? payerVPASearchValue?.trim() : "",
          paymentType: paymentTypeSearchvalue
            ? paymentTypeSearchvalue?.trim()
            : "",
          startAmount: startAmountSearchValue
            ? startAmountSearchValue?.trim()
            : 0,
          startDate: startDateSearchValue ? startDateSearchValue?.trim() : "",
          // token:token,
          transactionStatus: transectionStatusSearchValue
            ? transectionStatusSearchValue?.trim()
            : "",
          txnresponseCode: txnresponseCodeSearchValue
            ? txnresponseCodeSearchValue
            : 0,
        }
      );

      if (response?.statusCode === 200) {
        setData(
          response?.responseData?.map((item, i) => {
            return {
              id: i,
              ...item,
              domain: item?.domain,
              evokTxnCodeMode:
                item.txnStatus === "success" ? "0" : item.evokTxnCode,
              callbackJsonExist:
                item?.callbackJson != null ? "Received" : "Not Received",
            };
          })
        );

        setTransactionDetails(response?.responseData2);
        return;
      } else {
        setData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const USE_MULTIPLE_FILTER_BOX_API = async (
    dateSearchValue,
    endDateSearchValue,
    mid,
    startDateSearchValue,
    setErrorCodeList
  ) => {
    try {
      const response = await apiCallFnforPost(BOX_DATA_BY_CONDITION, {
        date: dateSearchValue ? dateSearchValue.trim() : "",

        endDate: endDateSearchValue ? endDateSearchValue.trim() : "",

        mid: mid,

        startDate: startDateSearchValue ? startDateSearchValue.trim() : "",
      });

      if (response?.statusCode === 304) {
        setErrorCodeList([]);

        return;
      }

      setErrorCodeList(response?.data ? response?.data : []);

      // console.log(response)
      // setCustomMultiApiResponse(response?.data?.responseData);
    } catch (error) {
      console.log(error);
    }
  };

  const USE_PAYIN_ADVANCE_FILTER_API = async (
    Url,
    emailSearchValue,
    orderNumberSearchValue,
    transactionIdSearchValue,
    startDate,
    endDate,
    mobileSearchValue,
    payerVPASearchValue,
    custRefNoSearchValue,
    mid,
    setData
  ) => {
    try {
      const response = await apiCallFnforPost(Url, {
        email: emailSearchValue ? emailSearchValue.trim() : "",
        orderNo: orderNumberSearchValue ? orderNumberSearchValue.trim() : "",
        txnId: transactionIdSearchValue
          ? transactionIdSearchValue.split("-")[1]
          : "",
        startDate: startDate ? startDate : "",
        endDate: endDate ? endDate : "",
        mobile: mobileSearchValue ? mobileSearchValue.trim() : "",
        payerVpa: "",
        custRefNo: custRefNoSearchValue ? custRefNoSearchValue.trim() : "",
        mid: mid,
        userVpa: payerVPASearchValue ? payerVPASearchValue.trim() : "",
        paymentType: "",
        txnDate: "",
        terminalId: "",
        amount: "",

        custIpAddress: "",
        custLocation: "",
        ifsc: "",
        bankLocation: "",
      });

      if (response.statusCode === 200) {
        setData(
          response.responseData.map((item, i) => {
            return {
              id: i,
              ...item,
              domain: item?.domain,
              callbackJsonExist:
                item?.callbackJson != null ? "Received" : "Not Rece ived",
            };
          })
        );
      } else {
        setData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return {
    USE_MULTIPLE_FILTER_BOX_API,
    USE_PAYIN_ADVANCE_FILTER_API,
    USE_PAYIN_MULTIPLE_FILTER_API,
    USE_WITHDRAW_MULTIPLE_FILTER_API,
    USE_RECONCILIATION_MULTIPLE_FILTER_API,
    USE_SETTLEMENT_MULTIPLE_FILTER_API,
    USE_GURANTEE_MULTIPLE_FILTER_API,
    USE_CHARGEBACK_MULTIPLE_FILTER_API,
    USE_PAYOUT_MULTIPLE_FILTER_API,
    USE_PAYOUT_MULTIPLE_FILTER_API,
    USE_COMMON_MULTIPLE_FILTER_API,
    USE_COMMON_ADVANCE_FILTER_API,
    // Other API service functions
  };
};

export default useFilterServices;
