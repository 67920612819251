import { useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import {
  requestSent,
  requestFailed,
  responseReceived,
} from "../store/Slices/utilSlice";
import {
  IFSC_VERIFY_API,
  LOG_OUT_API,
  UPDATE_MERCHANT_BANK_DETAILS,
  UPLOAD_MERCHANT_DOCS,
} from "./Constants";
import { useNavigate } from "react-router-dom";

const useApiServices = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { token } = useSelector((state) => state.userData);
  // console.log("token", token);
  const [loading, setLoading] = useState(false);

  const token = Cookies.get("access_token");

  const instance = axios.create();

  instance.interceptors.request.use(
    (config) => {
      setLoading(true);

      dispatch(requestSent());

      if (token && config.incHead) {
        config.headers.Authentication = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      setLoading(false);
      dispatch(requestFailed());
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => {
      setLoading(false);
      dispatch(responseReceived());
      return response.data;
    },
    (error) => {
      setLoading(false);
      dispatch(requestFailed());
      return Promise.reject(error);
    }
  );

  const apiCallFnforPost = async (url, payload) => {
    try {
      const response = await instance.post(url, payload, {
        incHead: true,
      });
      return response;
    } catch (error) {
      console.error("Error sending:", error);
      throw error;
    }
  };

  const apiCallFnforPostFormData = async (url, payload) => {
    try {
      const response = await axios.post(url, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (error) {
      console.error("Error sending:", error);
      throw error;
    }
  };

  const apiCallFnforPostWithParams = async (url, payload, param) => {
    try {
      const response = await instance.post(url, payload, {
        params: param,
        incHead: true, // This needs to be part of the configuration
      });
      return response;
    } catch (error) {
      console.error("Error sending:", error);
      throw error;
    }
  };

  const FileUploadAsFormData = async (url, file, mid) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("mid", mid);
      const response = await instance.post(url, formData, {
        incHead: true,
      });

      return response?.data;
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };

  const ifscCodeVerifier = async (ifscCode) => {
    try {
      const res = await instance.post(IFSC_VERIFY_API, { ifscCode });
      return { data: res?.data?.data, status: res?.data?.statusCode };
    } catch (error) {
      throw new Error("Error occurred while fetching data");
    }
  };

  const LogOutAPI = async () => {
    if (!token) {
      return;
    }
    try {
      const res = await axios.post(LOG_OUT_API, "", {
        headers: {
          Authentication: `Bearer ${token}`,
        },
      });
      if (res?.data?.statusCode === 200) {
        console.log("done");
        Cookies.remove("access_token");
        navigate("/");
      }
    } catch (error) {
      throw new Error("Error occurred while logging Out");
    }
  };

  const updateMerchantBankDetails = async (payload) => {
    try {
      const response = await axios.put(UPDATE_MERCHANT_BANK_DETAILS, payload);

      return response.data;
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };

  const uploadMerchantDocs = async (mid, docsName, docsFile) => {
    // alert("called");
    try {
      const formData = new FormData();
      formData.append("mid", mid);

      // formData.append("fileName", [...docsName]);
      // formData.append("pdfFiles", docsFile);

      docsFile.forEach((file) => {
        formData.append(`pdfFiles`, file);
      });
      docsName.forEach((name) => {
        formData.append(`fileName`, name);
      });

      const response = await axios.post(`${UPLOAD_MERCHANT_DOCS}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      // console.log(response);
      return response?.data?.statusCode;
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };

  const apiCallFnforPut = async (url, payload) => {
    try {
      const response = await instance.put(url, payload, {
        incHead: true,
      });
      return response;
    } catch (error) {
      console.error("Error sending:", error.message);
      throw error;
    }
  };

  const apiCallFnforPostForOpenUrl = async (url, payload) => {
    try {
      const response = await instance.post(url, payload, {
        incHead: false,
      });
      return response;
    } catch (error) {
      console.error("Error sending:", error);
      throw error;
    }
  };

  const apiCallFnforGet = async (url) => {
    try {
      const response = await instance.get(url, {
        incHead: true,
      });

      return response;
    } catch (error) {
      console.error("Error receiving:", error.message);
      throw error;
    }
  };

  const apiCallFnforDelete = async (url) => {
    try {
      const response = await instance.delete(url, {
        incHead: true,
      });

      return response;
    } catch (error) {
      console.error("Error receiving:", error.message);
      throw error;
    }
  };

  const apiCallFnforPutForOpenUrl = async (url, payload) => {
    try {
      const response = await instance.put(url, payload, {
        incHead: false,
      });
      return response;
    } catch (error) {
      console.error("Error sending:", error);
    }
  };

  const apiCallFnforGetOpenUrl = async (url) => {
    try {
      const response = await instance.get(url, {
        incHead: false,
      });
      return response?.data;
    } catch (error) {
      console.error("Error sending:", error);
    }
  };

  // Define other API service functions here...

  return {
    loading,
    apiCallFnforPost,
    apiCallFnforGet,
    apiCallFnforPut,
    apiCallFnforPutForOpenUrl,
    apiCallFnforGetOpenUrl,
    apiCallFnforPostForOpenUrl,
    apiCallFnforPostWithParams,
    apiCallFnforDelete,
    FileUploadAsFormData,
    apiCallFnforPostFormData,
    ifscCodeVerifier,
    uploadMerchantDocs,
    updateMerchantBankDetails,
    LogOutAPI,

    // Other API service functions
  };
};

export default useApiServices;
