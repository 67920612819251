/**
 * This function converts the date format.
 * @param {string} date - The date.
 * @returns {string} A formatted date.
 */
export const dateConvert = (date) => {
  const date1 = new Date(date);
  const formattedDate = date1.toLocaleDateString("en-CA", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  return formattedDate;
};

/**
 * This function converts the date format.
 * @param {string} inputDate - The date.
 * @returns {string} A formatted date.
 */
export const convertDateFormat = (inputDate) => {
  if (!inputDate) {
    return null;
  }
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
};

/**
 * This function checks that selected date is three months ago date or not.
 * @param {string} selectedDate - The date.
 * @returns {boolean}  true or false.
 */
export const checkThreeMonthAgoDate = (selectedDate) => {
  const selected = new Date(selectedDate);
  const today = new Date();

  // Calculate the difference in milliseconds
  const differenceInMilliseconds = today - selected;

  // Calculate the difference in days
  const differenceInDays = Math.floor(
    differenceInMilliseconds / (1000 * 60 * 60 * 24)
  );

  // Check if selected date is 90 days old
  if (differenceInDays > 90) {
     return true
  } else {
     return false
  }
};

/**
 * This function converts the time format.
 * @param {string} timestamp - The timestamp.
 * @returns {string} A formatted time.
 */
export const convertTime = (timestamp) => {

  if(!timestamp){
    return;
  }
  // Split the timestamp string into hours, minutes, and seconds
  const [hours, minutes, seconds] = timestamp?.split(":");
  
  // Convert hours to 12-hour format
  const formattedHours = (parseInt(hours, 10) % 12 || 12)?.toString()?.padStart(2, '0');

  // Determine AM or PM
  const period = parseInt(hours, 10) < 12 ? 'AM' : 'PM';

  // Construct the formatted time string without milliseconds
  const formattedTime = `${formattedHours}:${minutes}:${seconds?.split(".")[0]} ${period}`;

  

  return formattedTime;
}

 
/**
 * This function converts the time format.
 * @param {number} monthNumber - The timestamp.
 * @returns {string} Month name.
 */
export const getMonthName = (monthNumber) => {
  const months = [
    'January', 'February', 'March', 'April',
    'May', 'June', 'July', 'August',
    'September', 'October', 'November', 'December'
  ];

  
  if (monthNumber >= 1 && monthNumber <= 12) {
    
    return months[monthNumber];
  } else {
    return 'Invalid month number';
  }
}

/**
 * This function converts the date format.
 * @param {string} inputDateString - The date.
 * @returns {string} A formatted date.
 */
export const convertStringDateToDateType = (inputDateString) => {
  const dateParts = inputDateString.split(" ");

  const months = {
    Jan: 0,
    Feb: 1,
    Mar: 2,
    Apr: 3,
    May: 4,
    Jun: 5,
    Jul: 6,
    Aug: 7,
    Sep: 8,
    Oct: 9,
    Nov: 10,
    Dec: 11,
  };

  const day = parseInt(dateParts[2], 10);
  const month = months[dateParts[1]];
  const year = parseInt(dateParts[5], 10);
  const hours = parseInt(dateParts[3].split(":")[0], 10);
  const minutes = parseInt(dateParts[3].split(":")[1], 10);
  const seconds = parseInt(dateParts[3].split(":")[2], 10);

  const date = new Date(year, month, day, hours, minutes, seconds);
  return date
};

export const convertDate=(date )=>{
  // Input string
const dateString =date ;

// Create a map to convert month names to numerical values
const monthMap = {
  'Jan': 1, 'Feb': 2, 'Mar': 3, 'Apr': 4, 'May': 5, 'Jun': 6,
  'Jul': 7, 'Aug': 8, 'Sep': 9, 'Oct': 10, 'Nov': 11, 'Dec': 12
};

// Split the input string into components
const dateComponents = dateString.split(' ');

// Extract day, month, year, and time components
const day = parseInt(dateComponents[2]);
const month = monthMap[dateComponents[1]];
const year = parseInt(dateComponents[5]);
const time = dateComponents[3];

// Create a new Date object with the extracted components
const formattedDate = new Date(year, month, day);

 return formattedDate  ;
}

/**
 * This function checks that date is expired or not.
 * @param {string} toDate - The date.
 * @param {string} expiryDate - expiring date.
 * @returns {boolean}  true or false.
 */
export const isExpired = (toDate, expiryDate) => {
  const expirationDate = new Date(expiryDate);

  // Compare the two dates
  if (expirationDate < toDate) {
    return true; // The expiry date has passed
  } else {
    return false; // The expiry date is in the future
  }
}

/**
 * This function converts the lengthy numbers into compact number with K,L,Cr etc.
 * @param {number} number - The number.
 * @returns {string}  compact number.
 */
export function formatCompactNumber(number) {
  if (number < 0) {
    return "-" + formatCompactNumber(-number);
  }
  if (number < 1000) {
    return number;
  } else if (number >= 1000 && number < 1_000_00) {
    return (number / 1000).toFixed(2) + "K";
  } else if (number >= 1_000_00 && number < 1_000_000_0) {
    return (number / 1_000_00).toFixed(2) + "L";
  } else if (number >= 1_000_000_0 && number < 1_000_000_000_0) {
    return (number / 1_000_000_0).toFixed(2) + "Cr";
  } else if (number >= 1_000_000_000_00 && number < 1_000_000_000_000_0) {
    return (number / 1_000_000_000).toFixed(2) + "Ar";
  }
}

/**
 * This function  timestamp into formatted date.
 * @param {string} timestamp - The timestamp.
 * @returns {string} A formatted date.
 */
export const createDateFromTimestamp = (timestamp) => {
  
  // Check if the input timestamp is a string of 14 characters
  if (typeof timestamp !== 'string' || timestamp.length !== 14) {
    throw new Error('Timestamp must be a string of 14 characters in the format YYYYMMDDHHMMSS');
  }

  // Parse the timestamp
  const year = parseInt(timestamp.substring(0, 4), 10);
  const month = parseInt(timestamp.substring(4, 6), 10) - 1; // Month is zero-based in JS Date
  const day = parseInt(timestamp.substring(6, 8), 10);
  const hour = parseInt(timestamp.substring(8, 10), 10);
  const minute = parseInt(timestamp.substring(10, 12), 10);
  const second = parseInt(timestamp.substring(12, 14), 10);

   // Create the Date object
   const date = new Date(year, month, day, hour, minute, second);
    
   // Format the date to a readable string
   const options = { 
     year: 'numeric', 
     month: 'long', 
     day: 'numeric', 
     hour: '2-digit', 
     minute: '2-digit', 
     second: '2-digit' 
   };
   const formattedDate = date.toLocaleDateString('en-US', options);

   return formattedDate;
 };

 export function timestampToCustomDateFormat(timestamp) {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

// Example usage
 