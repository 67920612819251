import { jwtDecode } from "jwt-decode";
import React, { useState, useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import swal from "sweetalert";
import { VIRTUAL_TERMINAL_REQUEST_SUBMIT } from "../../Utils/Constants";
import useApiServices from "../../Utils/useApiService";
import { isVpaUpiRegexIsValid } from "../../Utils/validations";
import InputBox from "../../Common/InputBox/InputBox";
import { useSelector } from "react-redux";
import axios from "axios";

/** This component returns virtual payment.
 * @returns {ReactNode} this component returns inputs for virtual payment.
 */

export default function VirtualTerminal() {
  const { mid } = useSelector((state) => state.userData);
  const { apiCallFnforPost } = useApiServices();

  const [formData, setFormData] = useState({
    email: "",
    mobile: "",
    upi: "",
    amount: "",
  });

  const Type = localStorage.getItem("typeOfServices");
  const paymentType = JSON.parse(Type);

  const [responseData, setResponseData] = useState();

  const HandleForm = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    console.log(name, value);
    // Check if the entered value is a valid number
    if (name === "upi" || name === "name") {
      setFormData({ ...formData, [name]: value });
    } else {
      setFormData({ ...formData, [name]: value > 0 ? value : 0 });
    }
  };
 

  const handleValidations = (e) => {
    if (e.target.name === "upi") {
      if (!isVpaUpiRegexIsValid(e.target.value)) {
        document.getElementById("upiErr").classList.remove("d-none");
        document.getElementById("SubMitBtn").disabled = true;
        return;
      } else {
        document.getElementById("upiErr").classList.add("d-none");
        document.getElementById("SubMitBtn").disabled = false;
        return;
      }
    }

    if (e.target.name === "mobile") {
      // Mobile validation (example: 10 digits)
      if (!/^\d{10}$/.test(e.target.value)) {
        document.getElementById("MobileErr").classList.remove("d-none");
        document.getElementById("SubMitBtn").disabled = true;
        return;
      } else {
        document.getElementById("MobileErr").classList.add("d-none");
        document.getElementById("SubMitBtn").disabled = false;
        return;
      }
    }

    if (e.target.name === "email") {
      // Email validation
      if (
        !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(e.target.value)
      ) {
        document.getElementById("emailErr").classList.remove("d-none");
        document.getElementById("SubMitBtn").disabled = true;
        return;
      } else {
        document.getElementById("emailErr").classList.add("d-none");
        document.getElementById("SubMitBtn").disabled = false;
        return;
      }
    }

    document.getElementById("upiErr").classList.add("d-none");
    document.getElementById("SubMitBtn").disabled = false;
  };
  const handleSearch = async () => {
    const payload = {
      amount: parseInt(formData.amount),
      mid: mid,
      mobileNo: formData.mobile,
      // emailId: formData.email,
      udf1: formData.upi,
    };

    if (formData.amount === "") {
      swal({
        title: "Failed",
        text: "Please Enter Amount.",
        icon: "warning",
      });
      return;
    }

    if (parseInt(formData.amount) <= 0) {
      swal({
        title: "Failed",
        text: "Amount should be greater than zero.",
        icon: "warning",
      });
      return;
    }

    // if (formData.upi === "") {
    //   swal({
    //     title: "Failed",
    //     text: "Please Enter UPI ID.",
    //     icon: "warning",
    //   });
    //   return;
    // }

    try {
      const response = await apiCallFnforPost(
        VIRTUAL_TERMINAL_REQUEST_SUBMIT,
        payload
      );

      if (response.message === "SUCCESS") {
        setResponseData(response.responseData);
        swal({
          title: "Success",
          text: "Request Sent Successfully.",
          icon: "success",
        });
      } else {
        swal({
          title: "Failed",
          text: "Failed To Sent Request.",
          icon: "failed",
        });
      }

      setFormData({
        email: "",
        mobile: "",
        upi: "",
        amount: "",
      });
    } catch (error) {
      swal({
        title: "Something Went Wrong",
        text: "",
        icon: "failed",
      });
      // setShowLoader(false);
    }
  };

  return (
    <>
      
      <InputBox
        label={"Amount"}
        type={"number"}
        value={formData.amount}
        onChange={HandleForm}
        name={"amount"}
        required={true}
        placeholder={"Enter Amount"}
        onwheel={"this.blur()"}
      />

      <InputBox
        label={"Mobile Number"}
        type={"tel"}
        maxLength={10}
        minLength={10}
        value={formData.mobile}
        onChange={HandleForm}
        name={"mobile"}
        required={true}
        isMobile={"[0-9]{10}"}
        placeholder={"Enter Mobile"}
      />
      <InputBox
        label={"Name"}
        type={"text"}
        value={formData.name}
        onChange={HandleForm}
        name={"name"}
        required={true}
        placeholder={"Enter Name"}
      />
      <InputBox
        label={"VPA"}
        type={"text"}
        value={formData.upi}
        onChange={HandleForm}
        name={"upi"}
        required={true}
        placeholder={"Enter VPA / UPI"}
      />
      <div class="mt-4 d-flex justify-content-end">
        <button
          id="companyPanVerifyBtn"
          type="button"
          onClick={handleSearch}
          className="btn btn-primary"
        >
          Submit
        </button>
      </div>
    </>
  );
}
