import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";

import { DataGrid, GridDeleteIcon, GridToolbar } from "@mui/x-data-grid";
import { useEffect } from "react";

import swal from "sweetalert";
import styles from "./ArchieveReport.module.css";
import Modal from "../../Common/Modal/Modal";
// import TablePopUp from "./TablePopUp";

// import BalanceComponent from "../Balance";
import CheckExportConst from "../../Utils/PrivilagesUtilityFun/CheckExportConst";
import {
  GET_ALL_PAYOUT_REQUEST,
  PAYOUT_BALANCE_DEATILS,
  PAYOUT_REQUEST_DELETE,
  PAYOUT_REQ_SAVE,
} from "../../Utils/Constants";

import useApiServices from "../../Utils/useApiService";
import { columnForPayoutSection } from "../../Utils/ColumnForReports";
import BalanceComponent from "./BalanComponent";
import TablePopUp from "./TablePopUp";
import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import IconWrapper from "../../Common/IconWrapper/IconWrapper";

/**
 * @returns {ReactNode} - This component renders elements to raise payout request.
 */

function PayoutRequest() {
  const [payoutDetails, setPayoutDetails] = useState(null);
  const [apiResponse, setApiResponse] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [getAgain, setGetAgain] = useState(false);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);

  const { apiCallFnforDelete, apiCallFnforGet, apiCallFnforPost } =
    useApiServices();

  const [selectionModel, setSelectionModel] = useState([]);

  const handleCheckBox = (id) => {
    if (selectionModel.includes(id)) {
      // If the row is already selected, remove it from the selection
      setSelectionModel((prevSelection) =>
        prevSelection.filter((entry) => entry !== id)
      );
    } else {
      // If the row is not selected, add it to the selection
      setSelectionModel((prevSelection) => [...prevSelection, id]);
    }
  };

  const getSelectedRowsData = () => {
    return rows.filter((row) => selectionModel.includes(row.id));
  };

  const column = [
    {
      field: "delete",
      headerName: "Delete",
      sortable: false,
      width: 100,
      renderCell: (params) => (
        <IconButton
          onClick={() => handleDelete(params.row.id)}
          aria-label="delete"
        >
          <GridDeleteIcon />
        </IconButton>
      ),
    },
    {
      field: "checkBox",
      headerName: "Select",
      sortable: false,
      width: 100,
      renderCell: (params) => (
        <IconButton
        // aria-label="delete"
        >
          <input
            className="form-check-input"
            type="checkbox"
            value={selectionModel.includes(parseInt(params.row.id))}
            onClick={() => handleCheckBox(params.row.id)}
          />
        </IconButton>
      ),
    },
    ...columnForPayoutSection,
  ];

  const { mid } = useSelector((state) => state.userData);
  const [getReq, setGetReq] = useState(false);

  const handleAddButton = () => {
    setOpenPopUp(!openPopUp);
  };

  const rows = apiResponse.map((entry) => ({
    accountNumber: entry.accountNumber || "",
    checkBox: (
      <IconButton className="d-flex align-items-center justify-content-center">
        <input
          className="form-check-input"
          type="checkbox"
          value={selectionModel.includes(parseInt(entry.id))}
          onClick={() => handleCheckBox(entry.id)}
        />
      </IconButton>
    ),

    id: entry.id || "",
    amount: entry.amount || "",
    bankName: entry.bankName || "",
    branchName: entry.branchName || "",
    category: entry.category || "",
    email: entry.email || "",
    vendorName: entry.fullName || "",
    ifscCode: entry.ifscCode || "",
    mobileNo: entry.mobileNo || "",
    userVpa: entry.upi || "",
    payoutFor: entry.payoutFor.toUpperCase() || "",
    delete: (
      <IconButton onClick={() => handleDelete(entry.id)} aria-label="delete">
        <GridDeleteIcon />
      </IconButton>
    ),
  }));

  const getRequest = async () => {
    await apiCallFnforGet(GET_ALL_PAYOUT_REQUEST + mid)
      .then((response) => {
        if (response.statusCode === 200) {
          console.log(response.data)
          setApiResponse(response.data);
        } else if (response.statusCode === 304) {
          setApiResponse([]);
        } else {
          console.error("API Call Failed");

          // Handle failure
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const handleDelete = async (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this request!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          const res = await apiCallFnforDelete(PAYOUT_REQUEST_DELETE + id);
          if (res.statusCode === 200) {
            swal("Request has been deleted!", {
              icon: "success",
            });
          } else {
            swal("Error occurred while deleting the request!", {
              icon: "error",
            });
          }
          setGetReq(!getReq);
        } catch (error) {
          console.error("Error:", error.message);
          // Handle error
          swal("Error occurred while deleting the request!", {
            icon: "error",
          });
        }
      } else {
        // User canceled the delete action
        swal("Request deletion canceled!");
      }
    });
  };

  useEffect(() => {
    getPayoutBalanceData();
  }, []);

  useEffect(() => {
    getRequest(mid);

    setGrandTotal("");
    setSelectedRows([]);
  }, [getReq, openPopUp, getAgain]);

  useEffect(() => {
    let totalAmount = selectedRows.reduce(
      (acc, row) => acc + parseFloat(row.amount || 0),
      0
    );
    setGrandTotal(totalAmount.toFixed(2));
  }, [selectedRows, getReq, openPopUp]);

  const isPayoutActive = localStorage.getItem("isPayoutActive");

  const getPayoutBalanceData = async () => {
    try {
      const response = await apiCallFnforGet(PAYOUT_BALANCE_DEATILS + mid);
      if (response.statusCode === 200) {
        console.log(response?.data[0]);
        setPayoutDetails(response?.data[0]);
      } else {
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleDataSubmission = async () => {
    // Calculate the grand total of selected rows' amounts
    if (isSubmitting) {
      // Prevent multiple submissions while one is in progress
      return;
    }

    setIsSubmitting(true);
    // Prepare the data to be submitted
    const DataForSubmit = getSelectedRowsData();

    const convertToPayloadFormat = (DataForSubmit) => {
      return DataForSubmit.map((item) => {
        return {
          accountNumber: item.accountNumber || "",
          amount: item.amount || "",
          bankName: item.bankName || "",
          branchName: item.branchName || "",
          category: item.category || "",
          email: item.email || "",
          fullName: item.fullName || "",
          ifscCode: item.ifscCode || "",
          mid: mid,
          mobile: item.mobileNo || "",
          payoutFor: item.payoutFor || "",
          remark: item.remark || "",
          requestId: item.id || 0,
          upi: item.upi || "",
        };
      });
    };

    const payload = convertToPayloadFormat(DataForSubmit);

    if (payload.length) {
      try {
        // console.log(payload)
        // return
        const response = await apiCallFnforPost(PAYOUT_REQ_SAVE, payload);

        if (response?.statusCode === 309) {
          swal({
            title: "Alert",
            text: response?.message,
            icon: "error",
          });
          return;
        }

        if (response?.statusCode === 200) {
          swal({
            title: "Success",
            text: "Your Payout request has been submitted successfully.",
            icon: "success",
          });
          setGetReq(!getReq);
        } else if (response?.statusCode === 304) {
          swal({
            title: "Failed",
            text: "Your Payout request is initiated but declined by bank.",
            icon: "warning",
          });
        } else if (response?.statusCode === 600) {
          swal({
            title: "Failed",
            text: "Something Went Wrong.",
            icon: "error",
          });
        }
      } catch (error) {
        console.log("erorrporo", error.message);
        swal({
          title: "Failed",
          text: "Something Went Wrong.",
          icon: "error",
        });
      } finally {
        setIsSubmitting(false); // Reset the submission status to false
      }
    }

    // // Make an API call to submit the selected data
  };

  const {
    PayoutReqPrivilages,
    hasPrivilagesPTVendor,
    hasPrivilagesPTCustomer,
    hasPrivilagesViewPayoutReq,
  } = CheckExportConst();

  return (
    <>
      <div className="container-fluid">
        <div className="card">
          <div className="w-75 d-flex gap-3 mb-2">
            <div className="fs-5 text-danger border border-light rounded rounded-3 p-2 px-4">
              Maximum Payout Limit : {payoutDetails?.maximumPayoutLimit}
            </div>
            <div className="fs-5 text-danger border border-light rounded rounded-3  p-2 px-4">
              Minimum Payout Limit : {payoutDetails?.minimumPayoutLimit}
            </div>
            <div className="fs-5 text-danger border border-light rounded rounded-3 p-2 px-4">
              Charges On Limit Exceeds :{" "}
              {payoutDetails?.payoutChargeAfterLimitExceed}
            </div>
          </div>
          <div class="card-header p-3 d-flex flex-column flex-md-row justify-content-between align-items-center">
            <h4 class="mb-3 mb-md-0">Payout Requests</h4>
            <BalanceComponent
              isShowWalletBalance={false}
              isShowIMPSOrUPI={true}
            />
            <div class="d-flex align-items-center">
              {(hasPrivilagesPTVendor || hasPrivilagesPTCustomer) &&
              isPayoutActive === "true" ? (
                <div
                  className="btn-sm btn-primary text-white fs-4"
                  data-bs-toggle="modal"
                  data-bs-target="#AddPayoutCTVN"
                >
                  Add Request
                </div>
              ) : null}
              <Modal
                id="AddPayoutCTVN"
                title={"Payout Customer / Vendor"}
                onSubmit={handleAddButton}
                Width={"lg"}
              >
                <TablePopUp getAgain={getAgain} setGetAgain={setGetAgain} />
              </Modal>

              <Link className="text-black mx-2" to="/Reports/12">
                <IconWrapper title={"Go to Payout Report"}>
                  <i class="fa-solid fa-location-arrow text-dark"></i>
                </IconWrapper>
              </Link>
            </div>
          </div>

          {hasPrivilagesViewPayoutReq ? (
            <div className={`mt-3 ${styles["DataGridHeight"]}`}>
              <DataGrid
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                rows={rows}
                columns={column}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                  },
                }}
                sx={{
                  "& .css-1rtad1": {
                    display: "block",
                  },
                }}
              />
            </div>
          ) : null}
        </div>
      </div>

      <div className="d-flex justify-content-end ">
        <div
          className={`btn btn-sm btn-primary text-capitlize ml-2 mt-2 mx-5 fs-4 w-auto`}
          size="sm"
          variant="primary"
          onClick={handleDataSubmission}
          disabled={isSubmitting}
        >
          {!isSubmitting ? "Submit" : "Please Wait..."}
        </div>
      </div>
    </>
  );
}

export default PayoutRequest;
