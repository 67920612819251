import React, { useState } from "react";
import VirtualTerminal from "./VirtualTerminal";
import QR from "./QR";
import LinkBasedPayments from "./LinkBasedPayments";
import TapNPay from "./TapNPay";
import IconWrapper from "../../Common/IconWrapper/IconWrapper";
import CheckExportConst from "../../Utils/PrivilagesUtilityFun/CheckExportConst";
import swal from "sweetalert";
import axios from "axios";
import { ADD_UTR_TO_PAYIN_TXN, REQUEST_FOR_ZENEPE } from "../../Utils/Constants";
import { useSelector } from "react-redux";
import useApiServices from "../../Utils/useApiService";
import { timestampToCustomDateFormat } from "../../Utils/helper";

const Payments = () => {
  const [codeImge, setCodeImage] = useState(null);
  const Type = localStorage.getItem("typeOfServices");
  const paymentType = Type ? JSON.parse(Type) : null;

  const { mid } = useSelector((state) => state.userData);

  const { apiCallFnforPostWithParams , apiCallFnforPost} = useApiServices();

  const downloadQRCode = () => {
    const link = document.createElement("a");
    link.href = codeImge;
    link.download = "qr_code.png";
    link.click();
  };

  const openModal = async () => {
    // Create the input element
    const input = document.createElement("input");
    input.type = "number";
    input.className = "form-control";
    input.placeholder = "Enter Amount";
    input.min = 1;
    input.step = 1;

    // Prevent negative sign from being input
    input.addEventListener("input", () => {
      if (input.value < 1) {
        input.value = ""; // Clear the input if the value is less than 1
      }
    });

    // Show the SweetAlert modal
    const value = await swal({
      title: "Add Request",
      // text: "Enter Amount",
      content: input, // Use the custom input element
      buttons: {
        cancel: "Cancel",
        confirm: {
          text: "Submit",
          value: "submit",
        },
      },
    });

    const userInput = Number(input.value);
    if (userInput > 0) {
      try {
        // Perform the first API call using axios
        console.log("User input:", userInput);
        const response = await apiCallFnforPostWithParams(
          REQUEST_FOR_ZENEPE,
          null,
          {
            amount: userInput,
            mid: mid,
          }
        );

        console.log("First API response:", response);
        if (response.statusCode === 200) {
          console.log("First API response:", response.data);

          const payload={
            action: "testing",
            amount: response.data.amount,
            orderNumber: response.data.orderNo,
            status: response.data.txnStatus,
            txnDate: response.data.txnDate,
            txnId: response.data.txnId,
            // utr: utrNumber,
            vpa: response.data.userVpa,
          }

          // Prompt for UTR Number
          const utrValue = await swal({
            title: "UTR Number",
            text: "Please enter the UTR number:",
            content: {
              element: "input",
              attributes: {
                placeholder: "Enter UTR number",
                type: "text",
              },
            },
            buttons: {
              cancel: "Cancel",
              confirm: {
                text: "Submit",
                value: "submit",
              },
            },
          });

          if (utrValue) {
            // alert("UTR Number:", utrValue);
            
            // Call the second API with UTR Number
            const utrResponse = await apiCallFnforPost(
              ADD_UTR_TO_PAYIN_TXN,
              {
                action: "testing",
                amount: response.data.amount,
                orderNumber: response.data.orderNo,
                status: response.data.txnStatus,
                txnDate: timestampToCustomDateFormat(response.data.txnDate),
                txnId: response.data.txnId,
                utr: utrValue,
                vpa: response.data.userVpa || "",
              }
            );

            // Handle the response from the second API
            if (utrResponse.statusCode === 200) {
              console.log("Second API response:", utrResponse.data);
              swal("Success", "Data saved successfully.", "success");
            } else {
              swal(
                "Error",
                "Something went wrong.",
                "error"
              );
            }
          } else {
            swal("Invalid input", "Please enter a valid UTR number.", "error");
          }
        } else {
          swal("Error", "Something went wrong. Please try again.", "error");
        }
      } catch (error) {
        console.error("Error:", error);
        swal("API Error", "An error occurred while calling the API.", "error");
      }
    } else {
      swal(
        "Invalid input",
        "Please enter a number greater than zero.",
        "error"
      );
    }
  };

  const shareQRCode = async () => {
    if (navigator.share && codeImge) {
      try {
        const response = await fetch(codeImge);
        const blob = await response.blob();
        const file = new File([blob], "qr-code.png", { type: "image/png" });

        await navigator.share({
          files: [file],
          title: "QR Code",
          text: "Here is the QR code you requested.",
        });
      } catch (error) {
        console.error("Sharing failed:", error);
        swal("Error", "Failed to share the QR code", "error");
      }
    } else {
      swal("Error", "Web Share API is not supported in this browser", "error");
    }
  };

  const {
    hasPrivilagesTandP,
    hasPrivilagesLBP,
    hasPrivilagesQR,
    hasPrivilagesVT,
  } = CheckExportConst();

  return (
    <div class="content-body">
      <div class="container-fluid">
        <div class="row">
          {hasPrivilagesQR ? (
            <div class="col-xl-6">
              <div class="card text-center">
                {paymentType.includes("QR") ? (
                  <>
                    <div class="card-header">
                      <h5 class="card-title">Static QR</h5>
                      <div>
                        <IconWrapper title={"Download QR"}>
                          <i
                            class="fa-solid fa-download"
                            onClick={downloadQRCode}
                          ></i>
                        </IconWrapper>
                        <IconWrapper title={"Share QR"}>
                          <i
                            class="fa-solid fa-share-nodes"
                            onClick={shareQRCode}
                          ></i>
                        </IconWrapper>
                      </div>
                    </div>
                    <div class="card-body">
                      <QR setCodeImage={setCodeImage} />
                    </div>
                  </>
                ) : (
                  <>
                    <div class="card-header">
                      <h5 class="card-title">Static QR</h5>
                    </div>
                    <div class="card-body">
                      <h3 className="text-danger">
                        You have not subscribed for this service. Please contact
                        provider.
                      </h3>
                    </div>
                  </>
                )}
              </div>
            </div>
          ) : null}

          {hasPrivilagesVT ? (
            <div class="col-xl-6">
              <div class="card text-center">
                {paymentType.includes("VIRTUAL TERMINAL") ? (
                  <>
                    <div class="card-header">
                      <h5 class="card-title">Virtual Terminal</h5>
                      <div>
                        <button
                          id="AddReqBtn"
                          type="button"
                          onClick={openModal}
                          className="btn btn-primary"
                        >
                          Add Request
                        </button>
                      </div>
                    </div>
                    <div class="card-body">
                      <VirtualTerminal />
                    </div>
                  </>
                ) : (
                  <>
                    <div class="card-header">
                      <h5 class="card-title">Virtual Terminal</h5>
                    </div>
                    <div class="card-body">
                      <h3 className="text-danger">
                        You have not subscribed for this service. Please contact
                        provider.
                      </h3>
                    </div>
                  </>
                )}
              </div>
            </div>
          ) : null}

          {hasPrivilagesLBP ? (
            <div class="col-xl-6">
              <div class="card text-center">
                {paymentType.includes("DPC") ? (
                  <>
                    <div class="card-header">
                      <h5 class="card-title">Link Based Payments</h5>
                    </div>
                    <div class="card-body">
                      <LinkBasedPayments />
                    </div>
                  </>
                ) : (
                  <>
                    <div class="card-header">
                      <h5 class="card-title">Link Based Payments</h5>
                    </div>
                    <div class="card-body">
                      <h3 className="text-danger">
                        You have not subscribed for this service. Please contact
                        provider.
                      </h3>
                    </div>
                  </>
                )}
              </div>
            </div>
          ) : null}

          {hasPrivilagesTandP ? (
            <div class="col-xl-6">
              <div class="card text-center">
                {2 ? (
                  <>
                    <div class="card-header">
                      <h5 class="card-title">Tap And Pay</h5>
                    </div>
                    <div class="card-body">
                      <TapNPay />
                    </div>
                  </>
                ) : (
                  <>
                    <div class="card-header">
                      <h5 class="card-title">Tap And Pay</h5>
                    </div>
                    <div class="card-body">
                      <h3 className="text-danger">
                        You have not subscribed for this service. Please contact
                        provider.
                      </h3>
                    </div>
                  </>
                )}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Payments;
