import React, { useEffect, useState } from "react";
import { isVpaUpiRegexIsValid } from "../../Utils/validations";
import { keywords } from "../../Utils/ContantKeys";
import { useDispatch } from "react-redux";
import { setParameterObject } from "../../store/Slices/reportFilterSlice";
import swal from "sweetalert";


/**
 * This component renders a resuable Paramter selection component.
 * @param {string} type - this prop is defining type of report. 
 * @param {function} callDispatch - this is redux action function which saves the parameter value in redux store.
 * @returns {ReactNode} Component returns select option for parameter.
 */

const Parameter = ({ type, callDispatch}) => {
  const [range, setRange] = useState("");
  const [inputVal, setInputVal] = useState("");
  const [endAmount, setEndAmount] = useState("");
  const [startAmount, setStartAmount] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const [searchOption, setSearchOption] = useState("");

  useEffect(() => {
    setSearchOption("");
    setInterval("");
  }, []);

  const dispatch = useDispatch();

  const exampleValues = {
    Amount: "Ex- 5500",
    "Amount Range": "Ex- 100-500",
    "UPI ID / VPA": "Ex- abcde@xyz / 98XXXXXXX1@xyz",
    "Type of Payment": "Ex-  QR / SQR / DQR / UPI",
    "Customer Location": "Ex- Madhya Pradesh",
    "Bank Location": "Ex- Bhopal",
    "UTR Number": "UTR Number",
    "Guarantee Amount": "Guarantee Amount",
    "Reference Number": "Reference Number",
    "Net Amount": "Net Amount",
    "Status Code": "E05/E06/U30 etc.",
    // Add more options and example values as needed
  };

  const values = {
    Payin: [
      "Amount",
      "Amount Range",
      "UPI ID / VPA",
      "Type of Payment",
      "Status Code",
      "Customer Location",
      "Bank Location",
    ],
    Payout: ["Amount", "Amount Range"],
    Settlement: ["Amount", "Amount Range"],
    Chargeback: ["Amount", "Amount Range"],
    Release: ["Amount", "Amount Range"],
    Payment: ["Amount", "Amount Range"],
    Withdraw: ["Amount", "Amount Range","UTR Number"],
    Guarantee: ["Guarantee Amount","Guarantee Amount Range", "Reference Number",],
    Reconciliation: ["Amount", "Amount Range", "UPI ID / VPA"],
  };

  // const handelValidations = (e) => {
  //   if (searchOption === "VPA") {
  //     if (!isVpaUpiRegexIsValid(searchValue)) {
  //       setIsSearchValueError(true);
  //       setSearchValueError("Invalid Enter Valid VPA");
  //     }
  //     return;
  //   }
  // };

  const handleAmountRange = (e) => {
    const regex = /^[0-9-]*$/;
    if (!regex.test(e.target.value)) {
     swal("Warning!", "Please Enter Numbers Only.", "warning");
      return;
    }

    setRange(e.target.value);
    function splitString(inputString) {
      const parts = inputString.split("-").map((part) => part.trim());
      return parts;
    }

    const [startAmount, endAmount] = splitString(e.target.value);
    setStartAmount(startAmount);
    setEndAmount(endAmount);
 
    // handleGetRangeAmount(startAmount,endAmount);
 
  };

  // const handelSearchData = (e) => {
  //   setSearchValue(e.target.value);
  //   setIsSearchValueError(false);
  //   setSearchValueError("");
  // };

  useEffect(() => {
    dispatch(setParameterObject({ searchOption, inputVal, startAmount, endAmount }));
  }, [callDispatch]);

  return (
    <fieldset className="border-2 rounded-0 mt-2 mb-2">
      <legend className="text-start text-black">
        Select Parameter
      </legend>
      <div className="">
        <div className="row">
          <div className="col">
            <div className="">
              <select
                className="form-control fs-4"
                value={searchOption}
                onChange={(e) => setSearchOption(e.target.value)}
              >
                <option type="number" value="">
                  Select
                </option>
                {values[type]?.map((item, index) => {
                  return (
                    <option type="number" value={item} key={index}>
                      {item}
                    </option>
                  );
                })}
                {/* <option type="number" value="amount">
                  Amount
                </option>
                <option type="number" value="amountInBetween">
                  Amount Range
                </option> */}
              </select>
            </div>
          </div>

          <div className="col">
            <div className="form-group">
              {!searchOption.includes("Range") ? (
                <>
                  <input
                    className="advanced-input-box form-control fs-4"
                    type={searchOption == "Amount" ? "number" : "text"}
                    min={0}
                    value={inputVal}
                    // onChange={handleValue}
                    onChange={(e) => setInputVal(e.target.value)}
                  />
                  <span className="ml-2">
                    {searchOption && exampleValues[searchOption]}
                  </span>
                </>
              ) : (
                <>
                  <input
                    className="advanced-input-box form-control"
                    type="text"
                    min={0}
                    value={range}
                    onChange={(e) => handleAmountRange(e)}
                  />
                  <span className="ml-2">
                    {searchOption && exampleValues[searchOption]}
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </fieldset>
  );
};

export default Parameter;
